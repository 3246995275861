import { useState, useEffect } from 'react';

function App() {
  const [data, setData] = useState('');

  useEffect(() => {
    (async function () {
      const { text } = await( await fetch(`/api/status`)).json();
      setData(text);
    })();
  });

  function handleClick() {
    fetch(`/api/broadcast`)
  }

  return <>
  <div>{data}</div>
  <div><button onClick={handleClick}>Send</button></div>
  </>;
}

export default App;
import '../App.css'

function Home() {

  return (
    <>
      <div>
        <h1 id="tableLabel">Something Is Coming</h1>
        <p>A new and easy way to make lists <em>collaboratively</em></p>
        <p><em>Loading...</em></p>
      </div>
    </>
  )
}

export default Home

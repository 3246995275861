import { useEffect } from "react";
import "../App.css";
import * as signalR from "@microsoft/signalr";


function Number() {
  useEffect(() => {
    const messages = document.getElementById("messages");
    const apiBaseUrl = window.location.origin;

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiBaseUrl}/api`)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("newMessage", (message) => {
      if (messages) {
        messages.innerHTML = message;
      }
    });

    connection.start().catch(console.error);

    return () => {
      connection.stop().catch(console.error);
    };
  }, []);

  function handleClick() {
    fetch(`/api/broadcast`)
  }

  return (
    <>
      <h1>Random Number page</h1>
      <div><button onClick={handleClick}>Send</button></div>
      <div id="messages"></div>
    </>
  );
}

export default Number;

import '../App.css'

function Tasks() {
  return (
    <>
      <div>
        <h1 id="tableLabel">Tasks Here!</h1>
        <p>here are your tasks and items</p>
        <p><em>Loading...</em></p>
      </div>
    </>
  );
};

export default Tasks;
import "./App.css";
import Home from "./Pages/Home.tsx";
import Tasks from "./Pages/Tasks.tsx";
import HealthCheck from "./Pages/HealthCheck.tsx";
import RandoNumber from "./Pages/Number.tsx";
import TicTacExpandToe from "./Pages/TicTacExpandToe.tsx";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<Home />} />
      <Route path="tasks" element={<Tasks />} />
      <Route path="healthcheck" element={<HealthCheck />} />
      <Route path="number" element={<RandoNumber />} />
      <Route path="ttet" element={<TicTacExpandToe />} />
    </Route>
  )
);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;

import { useEffect, useState } from "react";
import "../App.css";
import * as signalR from "@microsoft/signalr";
import { Session } from "../Interfaces/Session";

function TicTacExpandToe() {
  const [session, setSession] = useState<Session | null>(null);
  const [playerName, setPlayerName] = useState<string>(""); // State for player name
  const apiBaseUrl = window.location.origin;

  const getCookie = (cookieName: string): string | null => {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split("; ");
    for (const cookie of cookies) {
      if (cookie.startsWith(name)) {
        return cookie.substring(name.length);
      }
    }
    return null;
  };

  useEffect(() => {
    const messages = document.getElementById("messages");

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiBaseUrl}/api/ttet-negotiate`)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("ttetEvent", (updatedSession: string) => {
      // Parse the JSON string into an object
      const parsedSession = JSON.parse(updatedSession);

      const processedSession: Session = {
        ...parsedSession,
        players: Array.isArray(parsedSession.players)
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            parsedSession.players.map((item: any) => [item.item1, item.item2])
          : [],
        playerQueue: Array.isArray(parsedSession.playerQueue)
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            parsedSession.playerQueue.map((item: any) => [
              item.item1,
              item.item2,
            ])
          : [],
      };

      setSession(processedSession); // Update session data on real-time event
      if (messages) {
        messages.innerHTML = `Session updated at ${new Date().toLocaleTimeString()}`;
      }
    });

    connection.start().catch(console.error);

    return () => {
      connection.stop().catch(console.error);
    };
  }, [apiBaseUrl]);

  useEffect(() => {
    // Fetch session data when the component mounts
    async function fetchSession() {
      try {
        const response = await fetch(`${apiBaseUrl}/api/ttet-get`);
        const rawData = await response.json();

        // Transform playerQueue and players into the expected format
        const processedSession: Session = {
          ...rawData.result,
          players: Array.isArray(rawData.result.players)
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              rawData.result.players.map((item: any) => [
                item.item1,
                item.item2,
              ])
            : [],
          playerQueue: Array.isArray(rawData.result.playerQueue)
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              rawData.result.playerQueue.map((item: any) => [
                item.item1,
                item.item2,
              ])
            : [],
        };

        setSession(processedSession);
      } catch (error) {
        console.error("Failed to fetch session data:", error);
      }
    }

    fetchSession();
  }, [apiBaseUrl]);

  // Render animated WINNER/LOSER overlay
  const renderWinnerOverlay = () => {
    if (!session || !session.winner) {
      return null;
    }

    const username = getCookie("username");
    const isWinner = session.winner === username;

    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          zIndex: 1000,
          color: isWinner ? "limegreen" : "red",
          fontSize: "5rem",
          fontWeight: "bold",
          animation: "fade-in 2s ease-in-out",
        }}
      >
        {isWinner ? "WINNER" : "LOSER"}
      </div>
    );
  };

  // Helper to join the game
  const joinGame = async () => {
    if (!playerName.trim()) {
      alert("Please enter your name.");
      return;
    }

    try {
      const response = await fetch(
        `${apiBaseUrl}/api/ttet-join?name=${encodeURIComponent(playerName)}`,
        {
          method: "POST",
        }
      );
      if (response.ok) {
        alert("Successfully joined the game!");
        document.cookie = `username=${encodeURIComponent(
          playerName
        )}; path=/; secure; samesite=strict`;
      } else {
        alert("Failed to join the game. Please try again.");
      }
    } catch (error) {
      console.error("Error joining the game:", error);
      alert("An error occurred while joining the game.");
    }
  };

  // Helper to start the game
  const startGame = async () => {
    try {
      const response = await fetch(`/api/ttet-start`, {
        method: "POST",
      });
      if (response.ok) {
        alert("Game started!");
      } else {
        alert("Failed to start the game. Please try again.");
      }
    } catch (error) {
      console.error("Error starting the game:", error);
      alert("An error occurred while starting the game.");
    }
  };

  // Function to reset the game
  const resetGame = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/api/ttet-reset`, {
        method: "POST",
      });
      if (response.ok) {
        alert("Game reset successfully!");
        setSession(null); // Clear the current session data to trigger a reload
      } else {
        alert("Failed to reset the game. Please try again.");
      }
    } catch (error) {
      console.error("Error resetting the game:", error);
      alert("An error occurred while resetting the game.");
    }
  };

  const handleCellClick = async (x: number, y: number, cell: string) => {
    if (!session || (cell !== "" && cell !== null)) return; // Ignore clicks on filled cells
  
    const currentPlayer = session.players[session.turnIndex % session.players.length];
    const username = getCookie("username");
  
    if (username !== currentPlayer[0]) {
      alert("It's not your turn!");
      return;
    }
  
    try {
      const response = await fetch(
        `${apiBaseUrl}/api/ttet-taketurn?x=${x}&y=${y}`,
        {
          method: "POST",
          credentials: "include", // Include the cookie in the request
        }
      );
  
      if (response.ok) {
        alert("Move successful!");
      } else {
        alert("Failed to take turn. Try again.");
      }
    } catch (error) {
      console.error("Error taking turn:", error);
      alert("An error occurred while taking your turn.");
    }
  };
  
  // Helper to render the board
  const renderBoard = () => {
    if (!session) {
      return <p>Loading session...</p>;
    }

    const {
      board,
      boardXSize,
      boardYSize,
      gameInProgress,
      players,
      turnIndex,
      winner
    } = session;

    // Lock the board if there's a winner
    const isBoardLocked = !!winner;

    // If game is in progress, render the session's actual board
    if (gameInProgress && board) {
      // Get the current player
      const currentPlayer = players[turnIndex % players.length];
      const username = getCookie("username");
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${board[0].length}, 1fr)`,
            gap: "5px",
            pointerEvents: isBoardLocked ? "none" : "auto",
            opacity: isBoardLocked ? 0.6 : 1
          }}
        >
          {board.map((row, rowIndex) =>
            row.map((cell, cellIndex) => (
              <div
                key={`${rowIndex}-${cellIndex}`}
                style={{
                  width: "30px",
                  height: "30px",
                  border: "1px solid black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: cell === "" || cell === null ? "#f0f0f0" : "#add8e6",
                  cursor:
                    username === currentPlayer[0] && (cell === "" || cell === null)
                      ? "pointer"
                      : "not-allowed",
                }}
                onClick={() => handleCellClick(rowIndex, cellIndex, cell)}
              >
                {cell === "" || cell === null ? "" : cell}
              </div>
            ))
          )}
        </div>
      );
    }

    // If game is NOT in progress, create an empty board based on boardXSize and boardYSize
    const rows = boardYSize ?? 3; // Default to 3 rows if boardYSize is null
    const cols = boardXSize ?? 3; // Default to 3 columns if boardXSize is null
    const emptyBoard = Array.from({ length: rows }, () => Array(cols).fill(0));

    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${cols}, 1fr)`,
          gap: "5px",
        }}
      >
        {emptyBoard.map((row, rowIndex) =>
          row.map((_, cellIndex) => (
            <div
              key={`${rowIndex}-${cellIndex}`}
              style={{
                width: "30px",
                height: "30px",
                border: "1px solid black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#f0f0f0",
              }}
            ></div>
          ))
        )}
      </div>
    );
  };

  // Helper to render players and player queue
  const renderPlayers = (players: Array<[string, string]>, title: string) => (
    <div>
      <h3>{title}</h3>
      <ul>
        {players.map(([name, symbol], index) => (
          <li key={index}>
            {name} ({symbol})
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div style={{ position: "relative" }}>
      {renderWinnerOverlay()}
      <h1>Game Session</h1>
      <p id="messages">Waiting for updates...</p>
      {!session ? (
        <p>Loading session...</p>
      ) : (
        <>
          <p>Session ID: {session.id}</p>
          <p>
            Board Dimensions: {session.boardXSize} x {session.boardYSize}
          </p>
          <p>Game In Progress: {session.gameInProgress ? "Yes" : "No"}</p>
          <h2>Game Board</h2>
          {renderBoard()}
          {renderPlayers(session.players, "Players")}
          {renderPlayers(session.playerQueue, "Player Queue")}
          <p>Current Turn Index: {session.turnIndex}</p>

          {/* Join Game */}
          <div>
            <input
              type="text"
              placeholder="Enter your name"
              value={playerName}
              onChange={(e) => setPlayerName(e.target.value)}
              style={{ marginRight: "10px" }}
            />
            <button onClick={joinGame}>Join Game</button>
          </div>

          {/* Start Game */}
          {!session.gameInProgress && (
            <div style={{ marginTop: "10px" }}>
              <button onClick={startGame}>Start Game</button>
            </div>
          )}

          {/* Reset Game Button */}
          <div style={{ marginTop: "20px" }}>
            <button onClick={resetGame} style={{ padding: "10px 20px", fontSize: "1rem" }}>
              Reset Game
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default TicTacExpandToe;
